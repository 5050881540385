<template>
  <vx-card
    :title="this.salesTargetID ? 'Edit Sales Target' : 'Create Sales Target'"
  >
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :disabled="this.salesTargetID ? true : false"
          :filterable="true"
          :options="optionTerritory"
          v-model="selectedTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Year</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          :disabled="this.salesTargetID ? true : false"
          name="year"
          format="yyyy"
          :inline="false"
          v-model="selectedTargetYear"
          minimum-view="year"
        >
        </datepicker>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :disabled="this.salesTargetID ? true : false"
          :filterable="true"
          :options="optionTargetType"
          :value="selectedTergetType"
          @input="onChangeTargetType"
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Attribute Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionAttributeType"
          v-model="selectedAttributeType"
        />
      </div>
    </div> -->

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" />
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%" v-if="this.salesTargetID">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Status</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input type="radio" id="active" value="Active" v-model="status" />
        <label for="active">Active</label>

        <input type="radio" id="inactive" value="Inactive" v-model="status" />
        <label for="inactive">Inactive</label>
      </div>
    </div> -->
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th width="10%">Target Unit</th>
              <th width="25%">Salesman</th>
              <th width="12%">Attribute Type</th>
              <th width="25%">Attribute Value</th>
              <th width="13%">Target</th>
              <th width="5%">
                Add/remove <br />
                Attribute
              </th>
              <th width="5%">Add/remove <br />Salesman</th>
              <th width="5%">Add/remove <br />Target Unit</th>
            </tr>
          </thead>

          <tbody>
            <tr
              :key="indexParent"
              v-for="(tr, indexParent) in salesTargetLines"
            >
              <td class="td vs-table--td" width="10%">
                <v-select
                  :filterable="true"
                  :options="optionTargetUnit"
                  :value="salesTargetLines[indexParent].selected_target_unit"
                  @input="
                    (val) => {
                      onChangeTargetUnit(val, indexParent);
                    }
                  "
                >
                </v-select>
              </td>
              <td colspan="6" width="85%">
                <table width="100%">
                  <tr
                    v-for="(child, indexChild) in salesTargetLines[indexParent]
                      .personals"
                    :key="indexChild"
                  >
                    <td
                      style="
                        padding-top: 0px;
                        padding-bottom: 0px;
                        padding-left: 0px;
                        border-top: 0;
                      "
                      width="29%"
                    >
                      <v-select
                        :filterable="false"
                        :options="
                          salesTargetLines[indexParent].personals[indexChild]
                            .option_personal
                        "
                        v-model="
                          salesTargetLines[indexParent].personals[indexChild]
                            .selected_personal
                        "
                        @input="
                          (val) => {
                            onChangePersonal(val, indexParent, indexChild);
                          }
                        "
                        @search="
                          (search, loading) => {
                            onSearchPersonal(
                              search,
                              loading,
                              indexParent,
                              indexChild
                            );
                          }
                        "
                      >
                      </v-select>
                    </td>
                    <td
                      style="
                        padding-top: 0px;
                        padding-bottom: 0px;
                        border-top: 0;
                      "
                    >
                      <table width="100%">
                        <tr
                          v-for="(child1, indexChild1) in salesTargetLines[
                            indexParent
                          ].personals[indexChild].attributes"
                          :key="indexChild1"
                        >
                          <td
                            style="
                              padding-top: 0px;
                              padding-left: 0px;
                              border-top: 0;
                            "
                            width="21%"
                          >
                            <v-select
                              :filterable="true"
                              :options="optionAttributeType"
                              v-model="
                                salesTargetLines[indexParent].personals[
                                  indexChild
                                ].attributes[indexChild1]
                                  .selected_attribute_type
                              "
                              @input="
                                (val) => {
                                  onChangeAttributeType(
                                    val,
                                    indexParent,
                                    indexChild,
                                    indexChild1
                                  );
                                }
                              "
                            >
                            </v-select>
                          </td>
                          <td
                            style="padding-top: 0px; border-top: 0"
                            width="46%"
                          >
                            <v-select
                              :disabled="
                                salesTargetLines[indexParent].personals[
                                  indexChild
                                ].attributes[indexChild1]
                                  .selected_attribute_type == 'General'
                                  ? true
                                  : false
                              "
                              :filterable="true"
                              :options="
                                salesTargetLines[indexParent].personals[
                                  indexChild
                                ].attributes[indexChild1].option_attribute_value
                              "
                              v-model="
                                salesTargetLines[indexParent].personals[
                                  indexChild
                                ].attributes[indexChild1]
                                  .selected_attribute_value
                              "
                              @input="
                                (val) => {
                                  onChangeAttributeValue(
                                    val,
                                    indexParent,
                                    indexChild,
                                    indexChild1
                                  );
                                }
                              "
                              @search="
                                (search, loading) => {
                                  onSearchAttributeValue(
                                    search,
                                    loading,
                                    indexParent,
                                    indexChild,
                                    indexChild1
                                  );
                                }
                              "
                            >
                            </v-select>
                          </td>
                          <td
                            style="padding-top: 0px; border-top: 0"
                            width="22%"
                          >
                            <input
                              type="number"
                              min="0"
                              class="vs-inputx vs-input--input normal"
                              style="border: 1px solid rgba(0, 0, 0, 0.2)"
                              v-model="
                                salesTargetLines[indexParent].personals[
                                  indexChild
                                ].attributes[indexChild1].target_value
                              "
                            />
                          </td>
                          <td
                            style="padding-top: 0px; border-top: 0"
                            width="9%"
                          >
                            <div class="vx-input-group flex">
                              <vs-button
                                v-if="
                                  salesTargetLines[indexParent].personals[
                                    indexChild
                                  ].attributes.length > 1
                                "
                                @click.stop="
                                  removeAttributeRow(
                                    indexParent,
                                    indexChild,
                                    indexChild1
                                  )
                                "
                                size="small"
                                color="danger"
                                icon-pack="feather"
                                icon="icon-trash"
                                style="margin-right: 5px"
                                title="Remove Salesman"
                              />

                              <vs-button
                                v-if="
                                  salesTargetLines[indexParent].personals[
                                    indexChild
                                  ].attributes.length -
                                    1 ==
                                  indexChild1
                                "
                                @click.stop="
                                  addAttributeRow(indexParent, indexChild)
                                "
                                size="small"
                                color="success"
                                icon-pack="feather"
                                icon="icon-plus"
                                title="Add Salesman"
                              />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td
                      style="
                        text-align: center;
                        padding-top: 0px;
                        padding-left: 0px;
                        border-top: 0;
                      "
                      width="5%"
                    >
                      <div class="vx-input-group flex">
                        <vs-button
                          v-if="
                            salesTargetLines[indexParent].personals.length > 1
                          "
                          @click.stop="
                            removeSalesmanRow(indexParent, indexChild)
                          "
                          size="small"
                          color="danger"
                          icon-pack="feather"
                          icon="icon-trash"
                          style="margin-right: 5px"
                          title="Remove Salesman"
                        />

                        <vs-button
                          v-if="
                            salesTargetLines[indexParent].personals.length -
                              1 ==
                            indexChild
                          "
                          @click.stop="addSalesmanRow(indexParent)"
                          size="small"
                          color="success"
                          icon-pack="feather"
                          icon="icon-plus"
                          title="Add Salesman"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td class="td vs-table--td" style="text-align: center" width="5%">
                <div class="vx-input-group flex">
                  <vs-button
                    v-if="salesTargetLines.length > 1"
                    @click.stop="removeTargetUnitRow(indexParent)"
                    size="small"
                    color="danger"
                    icon-pack="feather"
                    icon="icon-trash"
                    style="margin-right: 5px"
                    title="Remove Target Unit"
                  />

                  <vs-button
                    v-if="salesTargetLines.length - 1 == indexParent"
                    @click.stop="addTargetUnitRow()"
                    size="small"
                    color="success"
                    icon-pack="feather"
                    icon="icon-plus"
                    title="Add Target Unit"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div
          style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          "
        >
          <vs-button
            v-if="this.salesTargetID ? hasPermission('edit') : hasPermission('create')"
            class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleBack"
            >Back</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import _ from "lodash";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    PlusBox,
    vSelect,
    Datepicker,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/sales-target",
        salesTargetID: null,
        description: null,
        status: "Inactive",
        optionTerritory: [],
        selectedTerritory: null,
        selectedTargetYear: null,
        optionTargetType: ["Monthly", "Weekly"],
        selectedTergetType: null,
        optionAttributeType: [
          "General",
          "Principal",
          "Brand",
          "Product Category",
          "Distribution Channel",
          "Sales Channel",
        ],
        targetUnitMonthly: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        targetUnitWeekly: [],
        optionTargetUnit: [],
        selectedAttributeType: null,
        defaultSalesTargetLines: [
          {
            selected_target_unit: "",
            personals: [
              {
                option_personal: [],
                selected_personal: null,
                attributes: [
                  {
                    option_attribute_value: [],
                    selected_attribute_value: null,
                    selected_attribute_type: "",
                    target_value: 0,
                  },
                ],
              },
            ],
          },
        ],
        salesTargetLines: [],
        countLoading: 0,
      };
    },

    openLoading() {
      if (this.countLoading == 0) {
        this.$vs.loading();
      }
      this.countLoading++;
    },
    closeLoading() {
      if (this.countLoading == 1) {
        this.$vs.loading.close();
      }
      this.countLoading--;
    },
    handleBack() {
      this.$router.push({
        name: "sales-target",
      });
    },
    handleSubmit() {
      let validateRequired = false;
      if (!this.selectedTerritory) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      if (!this.selectedTargetYear) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Target Year is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      if (!this.selectedTergetType) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Target Type is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      let cekTargetUnit = this.salesTargetLines.filter((el1) => {
        if (!el1.selected_target_unit) {
          return true;
        } else {
          return false;
        }
      });

      if (cekTargetUnit.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Target Unit is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      let cekPersonal = this.salesTargetLines.filter((el) => {
        let cekPersonal1 = el.personals.filter((el1) => {
          if (!el1.selected_personal) {
            return true;
          } else {
            return false;
          }
        });

        if (cekPersonal1.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      if (cekPersonal.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Salesman is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      let cekAttributeType = this.salesTargetLines.filter((el) => {
        let cekAttributeType1 = el.personals.filter((el1) => {
          let cekAttributeType2 = el1.attributes.filter((el2) => {
            if (!el2.selected_attribute_type) {
              return true;
            } else {
              return false;
            }
          });
          if (cekAttributeType2.length > 0) {
            return true;
          } else {
            return false;
          }
        });
        if (cekAttributeType1.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      if (cekAttributeType.length > 0) {
        this.closeLoading();
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Attribute Type is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      let cekAttributeValue = this.salesTargetLines.filter((el) => {
        let cekAttributeValue1 = el.personals.filter((el1) => {
          let cekAttributeValue2 = el1.attributes.filter((el2) => {
            if (
              (el2.selected_attribute_type != "General" ||
                !el2.selected_attribute_type) &&
              !el2.selected_attribute_value
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (cekAttributeValue2.length > 0) {
            return true;
          } else {
            return false;
          }
        });
        if (cekAttributeValue1.length > 0) {
          return true;
        } else {
          return false;
        }
      });

      if (cekAttributeValue.length > 0) {
        this.closeLoading();
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Attribute Value is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        validateRequired = true;
      }

      if (!validateRequired) {
        this.openLoading();
        let salesTargetLines = [];
        this.salesTargetLines.forEach((el) => {
          let personals = [];
          el.personals.forEach((p) => {
            let attributes = [];
            p.attributes.forEach((a) => {
              attributes.push({
                attribute_type: a.selected_attribute_type,
                attribute_value_id: a.selected_attribute_value
                  ? a.selected_attribute_value.id
                  : null,
                target_value: parseFloat(a.target_value),
              });
            });
            personals.push({
              personal_id: p.selected_personal
                ? p.selected_personal.personal_id
                : null,
              attributes: attributes,
            });
          });
          salesTargetLines.push({
            target_unit: el.selected_target_unit,
            personals: personals,
          });
        });

        let body = {
          territory_id: this.selectedTerritory.id,
          target_year: this.selectedTargetYear
            ? moment(this.selectedTargetYear).format("YYYY")
            : null,
          target_type: this.selectedTergetType,
          description: this.description,
          status: this.status,
          sales_target_lines: salesTargetLines,
        };

        console.log(body);

        this.$validator.validateAll().then((result) => {
          if (result) {
            let res = null;
            if (this.salesTargetID) {
              res = this.$http.put(
                this.baseUrl + "/" + this.salesTargetID + "/update",
                body
              );
            } else {
              res = this.$http.post(this.baseUrl + "/create", body);
            }

            res
              .then((resp) => {
                if (resp.code == 200) {
                  this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });

                  this.closeLoading();
                  setTimeout(this.handleBack(), 2000);
                } else {
                  this.closeLoading();
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              })
              .catch((error) => {
                this.closeLoading();
                console.log(error);
              });
          }
        });
      }
    },
    getSalesTarget() {
      if (this.salesTargetID) {
        this.openLoading();
        this.$http
          .get(this.baseUrl + "/" + this.salesTargetID + "/detail")
          .then((resp) => {
            if (resp.code == 200) {
              this.selectedTerritory = resp.data.territory;
              this.selectedTargetYear = Date.parse(resp.data.target_year);
              this.selectedTergetType = resp.data.target_type;
              this.description = resp.data.description;
              this.status = resp.data.status;

              if (this.selectedTergetType == "Monthly") {
                this.optionTargetUnit = this.targetUnitMonthly;
              } else {
                this.optionTargetUnit = this.targetUnitWeekly;
              }

              if (resp.data.sales_target_lines) {
                let salesTargetLinesTemo = [];
                resp.data.sales_target_lines.forEach((el) => {
                  let personals = [];
                  if (el.personals.length > 0) {
                    el.personals.forEach((el1) => {
                      let attributes = [];

                      if (el1.attributes.length > 0) {
                        el1.attributes.forEach((el2) => {
                          attributes.push({
                            option_attribute_value: [],
                            selected_attribute_value: el2.attribute_value,
                            selected_attribute_type: el2.attribute_type,
                            target_value: el2.target_value,
                          });
                        });
                      } else {
                        attributes = [
                          {
                            option_attribute_value: [],
                            selected_attribute_value: null,
                            selected_attribute_type: null,
                            target_value: 0,
                          },
                        ];
                      }

                      personals.push({
                        option_personal: [],
                        selected_personal: el1.personal,
                        attributes: attributes,
                      });
                    });
                  } else {
                    personals = [
                      {
                        option_personal: [],
                        selected_personal: null,
                        attributes: [
                          {
                            option_attribute_value: [],
                            selected_attribute_value: null,
                            selected_attribute_type: null,
                            target_value: 0,
                          },
                        ],
                      },
                    ];
                  }

                  salesTargetLinesTemo.push({
                    personals: personals,
                    selected_target_unit: el.target_unit,
                  });
                });
                this.salesTargetLines = salesTargetLinesTemo;
              }
              this.closeLoading();
            } else {
              this.closeLoading();
            }
          });
      }
    },

    getTerritory() {
      this.openLoading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length == 0) {
                this.optionTerritory = [];
                this.selectedTerritory = null;
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.closeLoading();
          } else {
            this.closeLoading();
          }
        });
    },
    getAttributeValue(attributeType, indexParent, indexChild, indexChild1) {
      this.openLoading();
      this.$http
        .get(this.baseUrl + "/attribute-value", {
          params: {
            attribute_type: attributeType,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data) {
              this.salesTargetLines[indexParent].personals[
                indexChild
              ].attributes[indexChild1].option_attribute_value = resp.data;
            } else {
              this.salesTargetLines[indexParent].personals[
                indexChild
              ].attributes[indexChild1].option_attribute_value = [];
            }
            this.closeLoading();
          } else {
            this.closeLoading();
          }
        });
    },
    onChangeTargetType(val) {
      this.selectedTergetType = val;
      if (val) {
        if (val == "Monthly") {
          this.optionTargetUnit = this.targetUnitMonthly;
        } else {
          this.optionTargetUnit = this.targetUnitWeekly;
        }

        this.salesTargetLines.forEach((el) => {
          el.selected_target_unit = null;
        });
      } else {
        this.optionTargetUnit = [];
      }
    },

    onChangeTargetUnit(val, indexParent) {
      let cek = this.salesTargetLines.filter((el, index) => {
        if (el.selected_target_unit && val) {
          if (el.selected_target_unit == val && index != indexParent) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Target unit already selected",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.salesTargetLines[indexParent].selected_target_unit = null;
        return false;
      } else {
        this.salesTargetLines[indexParent].selected_target_unit = val;
      }
    },
    onChangePersonal(val, indexParent, indexChild) {
      let cek = this.salesTargetLines[indexParent].personals.filter(
        (el, index) => {
          if (el.selected_personal && val) {
            if (
              el.selected_personal.personal_id == val.personal_id &&
              index != indexChild
            ) {
              return true;
            }
          }
          return false;
        }
      );

      if (cek.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Salesman already selected",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.salesTargetLines[indexParent].personals[
          indexChild
        ].selected_personal = null;
        return false;
      } else {
        this.salesTargetLines[indexParent].personals[
          indexChild
        ].selected_personal = val;
      }
    },
    onSearchPersonal(search, loading, indexParent, indexChild) {
      if (search.length) {
        loading(true);
        this.searchPersonal(loading, search, this, indexParent, indexChild);
      }
    },
    searchPersonal: _.debounce(
      (loading, search, t, indexParent, indexChild) => {
        if (t.selectedTerritory) {
          t.$http
            .get(t.baseUrl + "/sales", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                search: search.trim(),
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  t.salesTargetLines[indexParent].personals[
                    indexChild
                  ].option_personal = resp.data.records;
                } else {
                  t.salesTargetLines[indexParent].personals[
                    indexChild
                  ].option_personal = [];
                }
                loading(false);
              } else {
                t.salesTargetLines[indexParent].personals[
                  indexChild
                ].option_personal = [];
                loading(false);
              }
            });
        } else {
          t.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Territory is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          loading(false);
        }
      },
      350
    ),
    onChangeAttributeValue(val, indexParent, indexChild, indexChild1) {
      let attributeType =
        this.salesTargetLines[indexParent].personals[indexChild].attributes[
          indexChild1
        ].selected_attribute_type;

      if (attributeType) {
        let cek = this.salesTargetLines[indexParent].personals[
          indexChild
        ].attributes.filter((el, index) => {
          if (
            el.selected_attribute_value &&
            el.selected_attribute_type != "General" &&
            val &&
            el.selected_attribute_type == attributeType
          ) {
            if (
              el.selected_attribute_value.id == val.id &&
              index != indexChild1
            ) {
              return true;
            }
          }
          return false;
        });

        if (cek.length > 0) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Attribute Value already selected",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          this.salesTargetLines[indexParent].personals[indexChild].attributes[
            indexChild1
          ].selected_attribute_value = null;
        }
      } else {
        this.salesTargetLines[indexParent].personals[indexChild].attributes[
          indexChild1
        ].selected_attribute_value = null;
      }
    },
    onSearchAttributeValue(
      search,
      loading,
      indexParent,
      indexChild,
      indexChild1
    ) {
      if (search.length) {
        loading(true);
        this.searchAttributeValue(
          loading,
          search,
          this,
          indexParent,
          indexChild,
          indexChild1
        );
      }
    },
    searchAttributeValue: _.debounce(
      (loading, search, t, indexParent, indexChild, indexChild1) => {
        let attributeType =
          t.salesTargetLines[indexParent].personals[indexChild].attributes[
            indexChild1
          ].selected_attribute_type;
        if (attributeType != "General" && attributeType != "") {
          t.$http
            .get(t.baseUrl + "/attribute-value", {
              params: {
                order: "id",
                sort: "asc",
                length: 100,
                attribute_type: attributeType,
                search: search.trim(),
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                t.salesTargetLines[indexParent].personals[
                  indexChild
                ].attributes[indexChild1].option_attribute_value = resp.data;
                loading(false);
              } else {
                t.salesTargetLines[indexParent].personals[
                  indexChild
                ].attributes[indexChild1].option_attribute_value = [];
                loading(false);
              }
            });
        } else {
          t.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Territory is required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          loading(false);
        }
      },
      350
    ),
    addAttributeRow(indexParent, indexChild) {
      this.salesTargetLines[indexParent].personals[indexChild].attributes.push({
        option_attribute_value: [],
        selected_attribute_value: null,
        selected_attribute_type: null,
        target_value: 0,
      });
    },
    removeAttributeRow(indexParent, indexChild, indexChild1) {
      this.salesTargetLines[indexParent].personals[
        indexChild
      ].attributes.splice(indexChild1, 1);
    },
    addSalesmanRow(indexParent) {
      this.salesTargetLines[indexParent].personals.push({
        option_personal: [],
        selected_personal: null,
        attributes: [
          {
            option_attribute_value: [],
            selected_attribute_value: null,
            selected_attribute_type: null,
            target_value: 0,
          },
        ],
      });
    },
    removeSalesmanRow(indexParent, indexChild) {
      this.salesTargetLines[indexParent].personals.splice(indexChild, 1);
    },
    addTargetUnitRow() {
      this.salesTargetLines.push({
        selected_target_unit: null,
        personals: [
          {
            option_personal: [],
            selected_personal: null,
            attributes: [
              {
                option_attribute_value: [],
                selected_attribute_value: null,
                selected_attribute_type: null,
                target_value: 0,
              },
            ],
          },
        ],
      });
    },
    removeTargetUnitRow(indexParent) {
      this.salesTargetLines.splice(indexParent, 1);
    },

    onChangeAttributeType(val, indexParent, indexChild, indexChild1) {
      let cek = this.salesTargetLines[indexParent].personals[
        indexChild
      ].attributes.filter((el, index) => {
        if (el.selected_attribute_type == "General" && val) {
          if (el.selected_attribute_type == val && index != indexChild1) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Attribute type already selected",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.salesTargetLines[indexParent].personals[indexChild].attributes[
          indexChild1
        ].selected_attribute_type = null;
        this.salesTargetLines[indexParent].personals[indexChild].attributes[
          indexChild1
        ].selected_attribute_value = null;
        this.salesTargetLines[indexParent].personals[indexChild].attributes[
          indexChild1
        ].option_attribute_value = [];
      } else {
        if (val != "General" || val != "") {
          this.getAttributeValue(val, indexParent, indexChild, indexChild1);
        } else {
          this.salesTargetLines[indexParent].personals[indexChild].attributes[
            indexChild1
          ].selected_attribute_value = null;
          this.salesTargetLines[indexParent].personals[indexChild].attributes[
            indexChild1
          ].option_attribute_value = [];
        }
      }
    },
  },
  created() {},
  beforeMount() {
    for (let i = 1; i <= 52; i++) {
      this.targetUnitWeekly.push("Week " + i);
    }
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-sales-target");

    this.salesTargetLines = this.defaultSalesTargetLines;
    this.getTerritory();

    this.salesTargetID = this.$route.params.id;
    this.getSalesTarget();
  },
  watch: {
    salesTargetLines: {
      deep: true,
      handler: (val) => {
        console.log(val);
      },
    },
    // selectedTergetType(val) {
    //   console.log(val);
    //   this.onChangeTargetType(val);
    // },
  },
};
</script>

<style>
.multiselect__input {
  z-index: 0;
}

.multiselect__content-wrapper {
  z-index: 10;
}
</style>
